<template>
  <!-- 车辆添加 -->
  <div>
    <Modal :value="value" title="详情" @input="(val) => $emit('input', val)" :width="800" @on-visible-change="modalChange">
      <Form :label-width="100">
        <Row>
          <Col span="12">
          <FormItem label="机构名称：">
            <span>{{ data.serviceName }}</span>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="管辖区域：">
            <span>{{ data.regionCodeName }}</span>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="联系人：">
            <span>{{ data.name }}</span>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="联系电话：">
            <span>{{ data.tel }}</span>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="联系地址：">
            <span>{{ data.serviceAddress }}</span>
          </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    cooperationDetailInfo: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      data: [],
      facilityTable: [
        {
          title: '车辆品牌',
          key: 'carModel',
        },
        //车辆类型\车牌号/颜色\长宽高
        {
          title: '车辆类型',
          key: 'carType',
        },
        {
          title: '车牌号',
          key: 'plate',
        },
        {
          title: '车辆颜色',
          key: 'carColor',
        },
        {
          title: '车辆厢长',
          key: 'lasting',
        },
        {
          title: '车辆厢宽',
          key: 'wide',
        },
        {
          title: '车辆厢高',
          key: 'high',
        },
      ],
    }
  },
  methods: {
    modalChange(validate) {
      this.data = this.cooperationDetailInfo
    },
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
.equipment {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}
</style>